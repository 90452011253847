export const environment = {
    production: false,
    readonly: false
}

export const apis = {
    baseUrl: 'https://demo-api.synergym2.com/api',
    //baseUrl: 'http://localhost:8000/api',
    googleApiKey: 'AIzaSyCD2nTygZ9zur-WDtgaW5MTK7w4-05k_Oo',
    // googleApiKey: 'AIzaSyBH2NFiXlM-Vt6Z08lo-26AvfyrOV9xvBM',
    googleCaptchaSiteKey: '',
    channel: 'demo-send-notification',
    archiveURL: 'https://synergymarchive.codegenio.com',
    socketURL: 'https://synergym-notification-api.unitedcheerleading.com'
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}
export const paymentUrl = 'https://demo-api.synergym2.com/api/student/pay-now'

export const appURL = 'https://demo.synergym2.com'
